<template>
  <div>
    <Toolbar :handle-delete="del" :handle-edit="editHandler">
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <div v-if="item">
      <v-card max-width="860px" class="mt-4 mx-auto">
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            {{ $t('Profile') }}
            <v-icon>mdi-account</v-icon>
          </v-tab>
          <v-tab>
            {{ $t('Tenants') }}
            <v-icon>mdi-home-city</v-icon>
          </v-tab>
          <v-tab>
            {{ $t('SpeakerFees') }}
            <v-icon>mdi-cash</v-icon>
          </v-tab>
          <v-tab :disabled="!activeTenantSet">
            {{ $t('SeminarModules') }}
            <v-icon>mdi-human-male-board</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat class="mt-5">
              <v-card-text class="text-center">
                <h4 class="mb-1 grey--text">{{ item.email }}</h4>
                <h1 class="font-weight-light mb-3 black--text">
                  {{ profile.salutation }} {{ profile.title }} {{ profile.firstName }} {{ profile.lastName }}
                </h1>
                <h5 class="mb-1 grey--text" v-if="profile.company">{{ profile.company }}</h5>
                <v-row>
                  <v-col cols="12" sm="4" offset-sm="2">
                    <h3>Bestätigt</h3>
                    <v-icon v-if="item.confirmed == null">mdi-check-circle</v-icon>
                    <v-icon v-else>mdi-close-circle</v-icon>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <h3>Aktiv</h3>
                    <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
                    <v-icon v-else>mdi-close-circle</v-icon>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h3>Erstellt</h3>
                    am <strong>{{ formatDateTime(item.createdAt, 'L') }}</strong><br />
                    von <strong>{{ item.createdBy }}</strong><br />
                  </v-col>
                  <v-col cols="12" sm="6" col>
                    <h3>Aktualisiert</h3>
                    am <strong>{{ formatDateTime(item.updatedAt, 'L') }}</strong><br />
                    von <strong>{{ item.updatedBy }}</strong><br />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat :loading="!tenantUsersLoaded" class="mt-5">
              <v-card-title>{{ $t('SpeakerTenantUsers') }}</v-card-title>
              <v-card-text>
                <template v-if="tenantUsersLoaded">
                  <v-data-table
                    :headers="tenantUserHeaders"
                    :items="tenantUserObjects"
                    :loading="loadingFlags.tenant_users"
                    :loading-text="$t('Loading...')"
                    class="elevation-1"
                    item-key="@id"
                    v-model="tenantUsersSelected"
                  >
                    <template slot="item.tenant" slot-scope="{ item }">
                      {{ getTenantItem(item['tenant'], 'name') }}
                    </template>
                    <ActionCell
                      :handle-delete="() => deleteTenantUserHandler(props.item)"
                      :handle-edit="() => createOrEditTenantUserHandler(props.item)"
                      slot="item.action"
                      slot-scope="props"
                    ></ActionCell>
                  </v-data-table>
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
                <v-btn color="primary" dark @click="createOrEditTenantUserHandler(null)">
                  {{ $t('AddTenantUser') }}
                </v-btn>
                <TenantUserDialog
                  v-model="showTenantUserDialogForm"
                  :tenant-user-item="tenantUserFormItem"
                  :handle-created="onTenantUserCreatedOrUpdated"
                  :handle-updated="onTenantUserCreatedOrUpdated"
                  :show-handle="showTenantUserDialogForm"
                  :available-tenants="tenantsForForm"
                  :title="$t('SpeakerTenantUsers')"
                  @close="tenantUserDialogControl(true)"
                />

              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat :loading="!feesLoaded" class="mt-5">
              <v-card-title>{{ $t('SpeakerFees') }}</v-card-title>
              <v-card-text>
                <template v-if="feesLoaded">
                  <v-data-table
                    :headers="feeHeaders"
                    :items="feeObjects"
                    :loading="loadingFlags.fees"
                    :loading-text="$t('Loading...')"
                    :handle-created="onFeeCreatedOrUpdated"
                    :handle-updated="onFeeCreatedOrUpdated"
                    class="elevation-1"
                    item-key="@id"
                    v-model="feesSelected"
                  >
                    <template slot="item.unit" slot-scope="{ item }">
                      {{ $t(item['unit']) }}
                    </template>
                    <template slot="item.rate" slot-scope="{ item }">
                      {{ (item['rate']) }}
                    </template>
                    <template slot="item.tenant" slot-scope="{ item }">
                      {{ getTenantItem(item['tenant'], 'name') }}
                    </template>
                    <ActionCell
                      :handle-delete="() => deleteFeeHandler(props.item)"
                      :handle-edit="() => createOrEditFeeHandler(props.item)"
                      slot="item.action"
                      slot-scope="props"
                    ></ActionCell>
                  </v-data-table>
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
                <v-btn color="primary" dark @click="createOrEditFeeHandler(null)">
                  {{ $t('AddSpeakerFee') }}
                </v-btn>
                <FeeDialog
                  v-model="showFeeDialogForm"
                  :fee-item="feeFormItem"
                  :tenant-users="tenantUserObjects"
                  :handle-created="onFeeCreatedOrUpdated"
                  :handle-updated="onFeeCreatedOrUpdated"
                  :show-handle="showFeeDialogForm"
                  :title="$t('AddSpeakerFee')"
                  @close="feeDialogControl(true)"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card v-if="activeTenantSet" flat :loading="!seminarModulesLoaded" class="mt-5">
              <v-card-title>{{ $t('SeminarModules') }}</v-card-title>
              <v-card-text>
                <template v-if="seminarModulesLoaded">
                  <v-data-table
                    :headers="seminarModuleHeaders"
                    :items="seminarModulesObjects"
                    class="elevation-1"
                    item-key="@id"
                  >
                    <template slot="item.seminar" slot-scope="{ item }">
                      {{ getSeminarItem(item['seminar'], 'name') }}
                    </template>
                    <template slot="item.date" slot-scope="{ item }">
                      {{ formatDateTime(getTimeSlotItem(item['timeSlot'], 'start')) }}
                    </template>
                    <ActionCell
                      :handleShow="() => showSeminarModule(props.item)"
                      slot="item.action"
                      slot-scope="props"
                    ></ActionCell>
                  </v-data-table>
                </template>
                <template v-else>
                  <p>Keine Daten verfügbar</p>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';
import FeeDialog from '../../components/fee/FeeDialog';
import TenantUserDialog from '../../components/tenantUser/Dialog';
import ActionCell from "@/components/ActionCell";
import TenantUserMixin from "@/mixins/TenantUserMixin";
import TenantMixin from "@/mixins/TenantMixin";
import ProfileMixin from "@/mixins/ProfileMixin";
import SeminarModuleMixin from "@/mixins/SeminarModuleMixin";
import SeminarMixin from "@/mixins/SeminarMixin";
import TimeSlotMixin from "@/mixins/TimeSlotMixin";
import FeeMixin from "@/mixins/FeeMixin";

const servicePrefix = 'Speaker';

export default {
  name: 'SpeakerShow',
  servicePrefix,
  components: {
    Loading,
    Toolbar,
    FeeDialog,
    TenantUserDialog,
    ActionCell,
  },
  mixins: [ShowMixin, TenantUserMixin, TenantMixin, ProfileMixin, SeminarModuleMixin, SeminarMixin, FeeMixin, TimeSlotMixin],
  mounted() {
    this.tenantGroupsGetSelectItems();
    this.tenantsGetSelectItems();
  },
  data() {
    return {
      tab: true,
      feesSelected: [],
      feeHeaders: [
        { text: this.$i18n.t('FeeUnit'), value: 'unit' },
        { text: this.$i18n.t('FeeRate'), value: 'rate' },
        { text: this.$i18n.t('FeeTenant'), value: 'tenant' },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false,
        },
      ],
      seminarModuleHeaders: [
        { text: this.$i18n.t('Name'), value: 'name' },
        { text: this.$i18n.t('Date'), value: 'date' },
        { text: this.$i18n.t('Number'), value: 'number' },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false,
        },
      ],
      feeFilter: {
        options: {
          sortBy: [],
          descending: false,
          page: 1,
          itemsPerPage: 15,
        },
        filters: {},
      },
      showFeeDialogForm: false,
      feeFormItem: {},
      tenantUsersSelected: [],
      tenantUserHeaders: [
        { text: this.$i18n.t('TenantUserName'), value: 'tenant' },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false,
        },
      ],
      tenantUserFilter: {
        options: {
          sortBy: [],
          descending: false,
          page: 1,
          itemsPerPage: 15,
        },
        filters: {},
      },
      showTenantUserDialogForm: false,
      tenantUserFormItem: {},
      tenantsForForm: [],
      loadingFlags: {
        'time_slots': false,
        'fees': false,
        'tenant_users': false,
      },
    };
  },
  computed: {
    ...mapFields('speaker', {
      isLoading: 'isLoading',
    }),
    ...mapFields('tenant', {
      tenantSelectItems: 'selectItems',
    }),
    ...mapFields('fee', {
      deletedFeeItem: 'deleted',
    }),
    ...mapGetters('speaker', { find: 'find' }),
    activeTenantSet() {
      return !(this.activeTenant === null);
    },
    tenantUsersLoaded() {
      return this.item.tenantUsers.length === this.tenantUserObjects.length;
    },
    tenantUserObjects() {
      return this.item.tenantUsers.map((tenantUser) => this.resolveTenantUser(tenantUser)).filter(e => e);
    },
    seminarModulesObjects() {
      if (this.activeTenant === null || Array.isArray(this.item.seminarModules) === false || Array.isArray(this.item.seminarModulesCoSpeaker) === false) {
        return [];
      }
      const seminarModulesSpeaker = this.resolveSeminarModules(this.item.seminarModules);
      const seminarModulesCoSpeaker = this.resolveSeminarModules(this.item.seminarModulesCoSpeaker);
      return seminarModulesSpeaker.concat(seminarModulesCoSpeaker);
    },
    seminarModulesLoaded() {
      if (Array.isArray(this.item.seminarModules) === false || Array.isArray(this.item.seminarModulesCoSpeaker) === false) {
        return true;
      }
      return (this.item.seminarModules.length + this.item.seminarModulesCoSpeaker.length) === this.seminarModulesObjects.length;
    },
    availableTenants() {
      return this.tenantSelectItems.filter((tenant) => this.tenantUserObjects.findIndex(p => p.tenant === tenant['@id']) === -1);
    },
    feeObjects() {
      return this.resolveFees(this.item.fees);
    },
    feesLoaded() {
      if (Array.isArray(this.item.fees) === false) {
        return true;
      }
      return this.item.fees.length === this.feeObjects.length;
    },
    profile() {
      if (this.profileCreate && (typeof this.item.profile === 'object' && this.item.profile !== null)) {
        // create object, keep it simple
        return this.item.profile;
      }
      if (typeof this.item.profile !== "undefined") {
        return this.resolveProfile(this.item.profile);
      }
      return null;
    },
  },
  methods: {
    ...mapActions('speaker', {
      deleteItem: 'del',
      reset: 'reset',
      retrieve: 'load',
      updateSpeaker: 'update',
    }),
    ...mapActions('fee', {
      deleteFeeItem: 'del',
      retrieveFee: 'load',
      updateFee: 'update',
    }),
    ...mapActions('tenantGroup', {
      tenantGroupsGetSelectItems: 'fetchSelectItems',
    }),
    feeDialogControl(close = false) {
      this.showFeeDialogForm = !close;
    },
    tenantUserDialogControl(close = false) {
      this.showTenantUserDialogForm = !close;
    },

    createOrEditFeeHandler(feeItem) {
      this.feeFormItem = feeItem ?? { speaker: this.item['@id'] };
      this.feeDialogControl();
    },
    createOrEditTenantUserHandler(tenantUserItem) {
      this.tenantUserFormItem = tenantUserItem ?? { speaker: this.item['@id'] };
      this.tenantsForForm = this.availableTenants;
      if (tenantUserItem !== null) {
        this.tenantsForForm.push(tenantUserItem.tenant);
      }
      this.tenantUserDialogControl();
    },

    showSeminarModule(seminarModuleItem) {
      this.$router.push({
        name: 'SeminarShow',
        params: { id: seminarModuleItem['seminar'] },
      });
    },

    onTenantUserCreatedOrUpdated(tenantUser) {
      this.$store.dispatch('addRelation', {
        iri: this.item['@id'],
        relationName: 'tenantUsers',
        toIri: tenantUser['@id'],
      });
    },
    onFeeCreatedOrUpdated(fee) {
      this.$store.dispatch('addRelation', {
        iri: this.item['@id'],
        relationName: 'fees',
        toIri: fee['@id'],
      });
    },

    deleteTenantUserHandler(tenantUserToDelete) {
      this.deleteTenantUserItem(tenantUserToDelete);
      this.$store.dispatch('removeRelation', {
        iri: this.item['@id'],
        relationName: 'tenantUsers',
        toIri: tenantUserToDelete['@id'],
      });
    },
    deleteFeeHandler(fee) {
      this.deleteFeeItem(fee);
      this.$store.dispatch('removeRelation', {
        iri: this.item['@id'],
        relationName: 'fees',
        toIri: fee['@id'],
      });
    },
  },
  watch: {
    item(newValue) {
      if ('undefined' !== typeof newValue) {
        this.loadingFlags.time_slots = true;
      }
    },
    deletedFeeItem() {
      this.showMessage(`Fee deleted.`);
    },
    deletedTenantUserItem() {
      this.showMessage(`TenantUser deleted.`);
    },
  },
};
</script>
